import { Injectable, inject, LOCALE_ID } from '@angular/core';
import { Analytics, logEvent, setUserProperties } from '@angular/fire/analytics';

const LOCAL_STORAGE_KEY = 'nexus-locale';

export const localeIdToName: {[locale: string]: string} = {
  'en': 'English',
  'es-MX': 'Español',
  'tr-TR': 'Türkçe',
};

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private readonly locale = inject(LOCALE_ID);

  constructor(private readonly analytics: Analytics) {
    let storedLocale = '';
    try {
      storedLocale = window.localStorage?.getItem(LOCAL_STORAGE_KEY) || '';
    } catch (e) {
      console.warn('Local storage failed', e);
    }

    if(storedLocale && localeIdToName[storedLocale]) {
      this.updateLocale(storedLocale, true);
    }

    setUserProperties(this.analytics, {locale: this.locale});
  }

  updateLocale(newLocale: string, storage = false) {
    if(newLocale === this.locale) return;

    try {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, newLocale);
    } catch (e) {
      console.warn('Local storage failed', e);
    }

    let url = window.location.href;
    if(!url.includes(`/${this.locale}/`)) {
      return;
    }
    
    if(!storage) {
      logEvent(this.analytics, 'locale-selected', {'oldLocale': this.locale, 'newLocale': newLocale});
    }

    const newUrl = url.replace(`/${this.locale}/`, `/${newLocale}/`);
    window.location.replace(newUrl);
  }
}
